// Function to fetch data and populate the HTML elements
function fetchData() {
    fetch('https://api.highnoon.pro/api/fi')
        .then(response => {
            // Check if the response is ok (status 200)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Populate the HTML elements with the data
            document.getElementById('stat-p2e').innerText = parseFloat(data.p2e).toFixed(2);
            document.getElementById('stat-earned').innerText = parseFloat(data.earned).toFixed(2);
            document.getElementById('stat-toburn').innerText = parseFloat(data.to_burn).toFixed(2);
            document.getElementById('stat-burned').innerText = parseFloat(data.burned).toFixed(2);
        }) // This closing parenthesis was missing in your code
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
        });
}

// Call fetchData on window load
window.onload = function() {
    fetchData();
}
